import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const StartTrialBtn = props => {
  return (
    <OutboundLink
      href={props.destination}
      class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
    >
      {props.label}
    </OutboundLink>
  )
}

export default StartTrialBtn
