import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

import StartTrialBtn from "../components/startTrialBtn"

export default class DemoThanks extends React.Component {
  componentDidMount() {
    window.analytics.track("requested_demo", {})
  }
  render() {
    return (
      <Layout>
        <SEO title="Thanks for your request!" />
        <PageTitle
          preheader="We've Got Your Details"
          title="Thanks for your request!"
          subtitle="We'll be in touch."
        ></PageTitle>

        <div class="pb-24 text-center">
          <p>One of our colleagues will be in touch shortly.</p>
        </div>
      </Layout>
    )
  }
}
